import imagesLoaded from 'imagesloaded';
import Isotope from 'isotope-layout';
import 'isotope-packery';

(function ($, window) {
    $(document).ready(function () {
        let $mix = $('.packery');
        var elem = document.querySelector('.packery');

        if (elem) {
            var iso = new Isotope(elem, {
                // options
                itemSelector: '.packery-item',
                layoutMode: 'packery',
                packery: {
                    itemSelector: '.packery-item',
                },
            });
        }
        $('[data-filter]').on('click', function (e) {
            e.preventDefault();
            $('[data-filter]').removeClass('is-active');
            $(this).not('.filter-key').addClass('is-active');
            iso.arrange({ filter: $(this).attr('data-filter') });
        });

        $('[data-filtertab]').on('click', function (e) {
            e.preventDefault();
            var $target = $($(this).attr('href'));

            if ($(this).hasClass('is-active')) {
                $(this).removeClass('is-active');
                $target.removeClass('is-visible');
            } else {
                $('[data-filtertab]').removeClass('is-active');
                $(this).addClass('is-active');

                $target
                    .parent()
                    .find('.filter-group.is-visible')
                    .removeClass('is-visible');
                $target.addClass('is-visible');
            }
        });
    });
})(jQuery, window);
