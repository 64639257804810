(function($) {
    // make a small naviagtion on page scroll
    $(window).bind('scroll', function(){

      var top = $(window).scrollTop();
      var $nav = $('.header');
      var $mobilenav = $('.mobile-nav');

      if(top >= 100) {
        $nav.addClass('is-scroll');
        $mobilenav.addClass('is-scroll');
      } else {
        $nav.removeClass('is-scroll');
        $mobilenav.removeClass('is-scroll');
      }

      // defaults
      var opacity = 1;
      var offset = 0;

      if(top<=10) {
        var opacity = 1;
        var offset = 50 * top/200;
      } else {
        var opacity = 1 - top/200;
        var offset = 50 * top/200;
      }

      $('.fader').css({
        'opacity': opacity,
        'margin-top': -offset,
        'margin-bottom': offset
      });

    });

})(jQuery);

