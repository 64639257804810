(function($, window) {

    $(document).ready(function() {
        $('.js-showmore').click(function(e) {
            e.preventDefault();
            var target = $(this).attr('href');
            $(target).slideToggle(400).toggleClass('is-active');
            $(this).toggleClass('rotate--180');
        });
    });

})(jQuery, window, undefined);
