export function getHeight(el) {
    var clone, height;

    clone = el.cloneNode(true);
    clone.style.cssText =
        'position: absolute; visibility: hidden; height: auto;';
    el.parentNode.appendChild(clone);
    height = clone.offsetHeight;
    el.parentNode.removeChild(clone);

    return height;
}

export function slideToggle(el, speed) {
    var height = el.offsetHeight;
    if (height === 0) {
        slideDown(el, speed);
    } else {
        slideUp(el, speed);
    }
}

export function slideDown(el, speed, transition, delay) {
    if (el.offsetHeight !== 0) {
        // return;
    }

    if (speed == undefined) {
        speed = '.3s';
    }

    if (transition == undefined) {
        transition = 'ease';
    }

    if (delay == undefined) {
        delay = '0s';
    }

    var originHeight = getHeight(el) + 'px';

    el.style.transition = 'all ' + speed + ' ' + transition + ' ' + delay;
    el.style.visibility = 'visible';
    el.setAttribute('aria-expanded', true);
    el.style.height = originHeight;
}

export function slideUp(el, speed, transition, delay) {
    if (el.offsetHeight === 0) {
        return;
    }

    if (speed == undefined) {
        speed = '.3s';
    }

    if (transition == undefined) {
        transition = 'ease';
    }

    if (delay == undefined) {
        delay = '0s';
    }

    el.style.transition = 'all ' + speed + ' ' + transition + ' ' + delay;
    el.style.visibility = 'hidden';
    el.setAttribute('aria-expanded', false);
    el.style.height = '0px';
}
