// We use a cookie to see which bits of our site you like, so we can make more of it (if that’s not cool, please do update your browser settings). Our privacy policy has more information.
window.addEventListener("load", function() {
    window.cookieconsent.initialise({
      "palette": {
        "popup": {
          "background": "#3c3c3b",
          "text": "#fff"
        },
        "button": {
          "background": "#ffb600",
          "text": "#fff"
        }
      },
      "content": {
        "message": "We use a cookie to see which bits of our site you like, so we can make more of it (if that’s not cool, please do update your browser settings).",
        "dismiss": "Yes, I know you use cookies",
        "link": "Our privacy policy has more information.",
          "href": "https://radix-communications.com/radix-privacy-and-data-use-policy/"
      }
    })
});
