(function($, window) {

    // resize items correctly
    // prevents row breaking when team items is too tall
    // move the team expanded where it needs to be
    function teamResize() {
        $('.teams-row').imagesLoaded(function() {

            // set a small start height, first item will be taller
            var height = 0;

            // reset team heights to auto
            $('.team').height('auto');

            // foreach team item
            $('.team').each(function() {
                // if the height is greater than the tallest recorded height
                if($(this).outerHeight() > height) {
                    // set the new record
                    height = $(this).outerHeight();
                }
            });

            // after all items have been measured
            // set them all to the same height as the tallest item
            $('.team').outerHeight(height);


            // reindex the teamExpanded
            // LOTS OF REPEATED CODE, MAKE THIS BETTER
            if($('.teamExpanded').length) {
                // the active team member position
                var i = $('.js-team.active').index('.js-team');
                var width = $(window).width();
                // the num of columns
                var columns = 4;
                if(width <= 800) { columns = 3; }
                if(width <= 600) { columns = 2; }
                if(width <= 480) { columns = 1; }
                // the row to place
                var row = Math.floor(i/columns) + 1;
                // the position
                var position = (columns * row) -1;

                var $html = $('.teamExpanded');
                $('.teamExpanded').remove();
                $('.js-team:eq(' + position + ')').after($html);
            }

        });

    };

    window.teamResize = teamResize;

    $(document).ready(function() {

        // resize team items on page load, but after images have loaded
        $('.teams-row').imagesLoaded(function() {
            teamResize();
        });

        // resize items on window resize
        $(window).on('resize', teamResize);

        // js-team is on a .column
        $(document).on('click', '.js-team', function(e) {

            e.preventDefault();

            // get the index of the column item
            var i = $(this).index('.js-team'),
                // get the width of the window
                width = $(window).width(),
                // starting num columns at width
                columns = 4,
                // total number of team items
                total = $('.js-team').length;

            // where the expanded view appears depends on the
            // number of columns per row
            if(width <= 800) { columns = 3; }
            if(width <= 600) { columns = 2; }
            if(width <= 480) { columns = 1; }

            // the row number
            // not zero based
            var row = Math.floor(i/columns) + 1;

            // is the last item in the row
            // index = (columns * row) -1
            var position = (columns * row) -1;

            // the position is related to the last team member in a row
            // if the last row has less in it, the position will be to big
            // set the position to match the total number of team members -1
            if(position > $('.team').length -1 ) {
                position = $('.team').length -1;
            }

            var $content = $(this).find('.team-more').html();
            var $html = $('<div class="column one-whole teamExpanded"></div>').html($content);

            // THE BELOW COULD BE BETTER
            // if this item is already shown, hide it
            if($(this).hasClass('active')) {

                $(this).removeClass('active');
                // remove it
                $('.teamExpanded').slideUp(300, function() {
                    $(this).remove();
                });

                return;
            }

            // if a team info already shown
            if($('.teamExpanded').length) {

                // remove active class from item
                $('.js-team.active').removeClass('active');
                $(this).addClass('active');

                $('.teamExpanded').slideUp(300, function() {
                    $(this).remove();
                    $('.js-team:eq(' + position + ')').after($html);
                    $html.slideDown(300);
                });
            } else {
                $(this).addClass('active');
                $('.js-team:eq(' + position + ')').after($html);
                $html.slideDown(300);
            }

        });

    });

})(jQuery, window, undefined);
