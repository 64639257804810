(function($) {
    $(document).ready(function() {

        // changes url
        function changeState(title, url) {
            //Change URL
            window.history.replaceState(title, title, url);

            //Change meta title
            $('title').html(title);
        }

        // The subject of the script are the page links in the sub menu
        $('.subpage').click(function(e){

            e.preventDefault();

            // Gather together all the bits and bobs from the link
            // Target url, the name for the head of the page, the
            // title at the top of the page and where the new content will go.
            var $icon = $(this);
            var link = $icon.attr('href');
            var title = $icon.attr('title');
            var $header = $('.page-header');
            var $pageTitle = $('.page-title');
            var $standfirst = $('.page-title').next('.standfirst');
            var $content = $('#ajax');
            var $loading = $('.loading');


            // Fadeout the title and the changing content.
            $header.toggleClass('loading-transition');

            $content.animate( {'opacity' : 0 }, 200, function(){

                // Fade in the loading animation.
                $loading.fadeIn( 200, function(){

                    // Fade in the loading animation.
                    // var toggleFade = function(){$('.loading').toggleClass('fading');};

                    // Only show if loading takes some time
                    // setTimeout( toggleFade, 200);

                    // Make the ajax request.
                    // $( Place to change ).load( url + 'section to load', function(){
                    $('#ajax').load(link + ' #ajax > *', function() {

                        var sfContent = $('#ajax .standfirst').html();
                        console.log(sfContent);
                        $('#ajax .standfirst').remove();

                        if(sfContent == undefined) {
                            sfContent = '';
                        }

                        $standfirst.html(sfContent);

                        // Call the function to change the url address
                        changeState(title , link);

                        // replace page title with the new one
                        $('.page-title').html(title);

                        $header.toggleClass('loading-transition');

                        $loading.fadeOut(200, function(){

                            $content.animate({'opacity' : 1 }, function() {
                                $('.teams-row').imagesLoaded(function() {
                                    teamResize();
                                });
                            });

                            // Change the appearance of the submenu links to show which is active
                            $('.menu-subpage .active').removeClass('active');
                            $icon.addClass('active');

                        });
                        // Fade out the loading animation
                        // $('.loading').toggleClass('fading');


                        // Fade in the new content and the new title
                        // $pageTitle.toggleClass('loading-transition');
                        // $content.toggleClass('loading-transition');

                        // Change the appearance of the submenu links to show which is active
                        // $('.menu-subpage .active').removeClass('active');
                        // $icon.addClass('active');

                    });

                });
            });


        });
    });
})(jQuery);
