(function($, window) {

    $(document).ready(function() {

        $(".slides").cycle({
            speed: 1000,
            pager: ".pagers",
            pagerTemplate: '<span class="pager"></span>'
        });
    });

})(jQuery, window, undefined);
