(function ($, window) {
    $(document).ready(function () {
        if ($('.post-body').length) {
            $(window).on('scroll', function () {
                // make it so the article ends meets middle of screen
                var scrollTop = $(document).scrollTop(),
                    articleHeight =
                        $('.post-body').height() +
                        $('.post-body').position().top -
                        $(window).height() / 2,
                    progress = (scrollTop / articleHeight) * 100;

                if (progress > 100) {
                    progress = 100;
                }

                $('.js-progress').width(progress + '%');
            });
        }
    });
})(jQuery, window, undefined);
