import { slideToggle } from 'Utilities/sliding.js';

function toggleMobileMenu() {
    var body = document.querySelector('body');
    var menu = document.querySelector('.nav--main');
    var trigger = document.querySelector('.js-mobile-menu');
    var header = document.querySelector('.header');

    menu.classList.toggle('is-open');
    trigger.classList.toggle('is-active');
    header.classList.toggle('is-active');

    slideToggle(menu);
}

var hamburger = document.querySelectorAll('.js-mobile-menu');

for (var i = 0, len = hamburger.length; i < len; i++) {
    hamburger[i].addEventListener('click', function (e) {
        toggleMobileMenu();
    });
}

var menu = document.querySelector('.nav--main');
document.addEventListener('keyup', function (e) {
    if (e.key == 'Escape' && menu.classList.contains('is-open')) {
        hamburger[0].focus();
        toggleMobileMenu();
    }
});

if (window.innerWidth <= 800) {
    var menu = document.querySelector('.nav--main');
    menu.setAttribute('aria-expanded', false);
}
