let inputs = document.querySelectorAll('.search-form input');

inputs.forEach((input) => {
    input.addEventListener('change', (e) => {
        if (e.target.value !== '') {
            e.target.classList.add('not-empty');
        } else {
            e.target.classList.remove('not-empty');
        }
    });
});
