(function($, window) {

    $(document).ready(function() {

        $('.js-tab').click(function(e) {
            e.preventDefault();

            var show = true;

            if($(this).hasClass('is-active')) {
                show = false;
            }

            // hide the current item
            $('.js-tab.is-active').removeClass('is-active');
            $('.tab-container.is-active').hide().removeClass('is-active')

            if(show) {
                $(this).addClass('is-active');
                var target = $(this).attr('href');
                $(target).show().addClass('is-active');
            }

        });

    });

})(jQuery, window);
