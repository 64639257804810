(function($, window) {

    $(document).ready(function(){
        // Target your .container, .wrapper, .post, etc.
        $(".post-video").fitVids();
    });

    $('.video').vide({
        mp4:  'https://player.vimeo.com/external/168756594.hd.mp4?s=bab5c12c68393003b9013937d669aef9acb7ac25&profile_id=174'
    }, {
        volume: 0,
        playbackRate: 1,
        muted: true,
        loop: true,
        autoplay: true,
        posterType: 'none', // Poster image type. "detect" — auto-detection; "none" — no poster; "jpg", "png", "gif",... - extensions.
        resizing: true, // Auto-resizing, read: https://github.com/VodkaBears/Vide#resizing
        //bgColor: '#ffb600', // Allow custom background-color for Vide div,
    });

})(jQuery, window, undefined);
