// https://github.com/nico3333fr/jquery-accessible-subnav-dropdown/blob/master/jquery-accessible-subnav-dropdown.js
const menuItems = document.querySelectorAll('.nav .main-menu > li');

menuItems.forEach(item => {
    item.addEventListener('keydown', e => {
        // Check if this is a top level menu item
        let isMenu = e.target.parentNode.parentNode.classList.contains(
            'main-menu'
        );
        // Check if this is a sub menu item
        let isSubMenu = e.target.parentNode.parentNode.classList.contains(
            'sub-menu'
        );

        // Set up some resuable names
        let nextItem = '';
        let prevItem = '';

        // event keyboard left
        if (e.keyCode == 37) {
            if (isMenu) {
                // Look up to the <li> then left to the previous <li>
                nextItem = e.target.parentNode.previousElementSibling;
                if (nextItem) {
                    // Find the anchor tag from there
                    nextItem.querySelector('a').focus();
                }
            }

            if (isSubMenu) {
                // Look up to the <li> then up to the .sub-menu then up to the
                // <li> then left to the previous <li>
                prevItem =
                    e.target.parentNode.parentNode.parentNode
                        .previousElementSibling;
                if (prevItem) {
                    // Find the anchor tag from there
                    prevItem.querySelector('a').focus();
                }
            }
            e.preventDefault();
        }

        // event keyboard right
        if (e.keyCode == 39) {
            if (isMenu) {
                // Look up to the <li> then right to the next <li>
                nextItem = e.target.parentNode.nextElementSibling;
                if (nextItem) {
                    // Find the anchor tag from there
                    nextItem.querySelector('a').focus();
                }
            }
            if (isSubMenu) {
                // Look up to the <li> then up to the .sub-menu then up to the
                // <li> then right to the next <li>
                prevItem =
                    e.target.parentNode.parentNode.parentNode
                        .nextElementSibling;
                if (prevItem) {
                    // Find the anchor tag from there
                    prevItem.querySelector('a').focus();
                }
            }
            e.preventDefault();
        }

        // event keyboard up
        if (e.keyCode == 38) {
            if (isSubMenu) {
                // Look up to the <li> then left to the previous <li>
                prevItem = e.target.parentNode.previousElementSibling;
                if (prevItem) {
                    // Find the anchor tag from there
                    prevItem.querySelector('a').focus();
                } else {
                    // look up to the <li> then up to the .sub-menu then up to the <li>
                    e.target.parentNode.parentNode.parentNode
                        .querySelector('a')
                        .focus();
                }
            }
            e.preventDefault();
        }

        // event keyboard down
        if (e.keyCode == 40) {
            if (isSubMenu) {
                // Look up to the <li> and then right to the next <li>
                nextItem = e.target.parentNode.nextElementSibling;
                if (nextItem) {
                    // Find the anchor tag from there
                    nextItem.querySelector('a').focus();
                }
            }

            if (isMenu) {
                // Find the sub menu if there is one
                if (e.target.parentNode.querySelector('.sub-menu')) {
                    // Get the first item there and focus is
                    e.target.parentNode
                        .querySelector('.sub-menu')
                        .querySelector('li > a')
                        .focus();
                }
            }
            e.preventDefault();
        }
    });
});

// // @TODO: WIP
// // https://trello.com/c/yo53kuSu
// const hasChildren = document.querySelectorAll(
//     '.main-menu .menu-item-has-children'
// );
//
// if (window.innerWidth < 800) {
//     for (var i = 0, len = hasChildren.length; i < len; i++) {
//         const item = hasChildren[i];
//
//         const link = document.createElement('button');
//         link.classList.add('js-sub-nav-trigger');
//
//         const svg = document.createElementNS(
//             'http://www.w3.org/2000/svg',
//             'svg'
//         );
//         svg.classList.add('icon');
//         svg.innerHTML = '<use xlink:href="#icon--button"></use>';
//
//         link.appendChild(svg);
//         item.appendChild(link);
//
//         const subMenuParent = item.cloneNode(true);
//         subMenuParent.querySelector('.sub-menu').remove();
//         link.previousElementSibling.insertBefore(
//             subMenuParent,
//             link.previousElementSibling.firstChild
//         );
//
//         subMenuParent.classList.add('sub-menu-parent');
//
//         const backTrigger = subMenuParent.querySelector('.js-sub-nav-trigger');
//         backTrigger.addEventListener('click', function() {
//             this.parentNode.parentNode.classList.remove('is-visible');
//         });
//
//         link.addEventListener('click', function(e) {
//             e.preventDefault();
//             this.previousElementSibling.classList.add('is-visible');
//         });
//     }
// }
